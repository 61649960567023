import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import SkeletonText from '../SkeletonText';

const StyledStatusRowWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  gap: theme.spacing(2),
}));

const StyledStatusFirstCellWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  flexGrow: 1,
}));

const StyledStatusSecondCellWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export default function WebsiteSectionGraderStatus() {
  return (
    <StyledStatusRowWrapper>
      <StyledStatusFirstCellWrapper>
        <SkeletonText animation="wave" variant="string" width="100%" height={20} />
      </StyledStatusFirstCellWrapper>
      <StyledStatusSecondCellWrapper>
        <SkeletonText animation="wave" variant="string" width={66} height={30} />
        <SkeletonText animation="wave" variant="string" width={24} height={24} />
      </StyledStatusSecondCellWrapper>
    </StyledStatusRowWrapper>
  );
}

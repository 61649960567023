import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import GraderResults from '../components/grader/GraderResults';
import GraderGetYourScore from '../components/grader/GraderGetYourScore';
import { loadInitialGraders } from '../../graders/website/data/grader-slice';
import { COMPLETE_GRADER } from '../../graders/website/data/grader-constants';
import LoadingSkeletonGrader from '../components/loadingSkeletons/grader/LoadingSkeletonGrader';

const StyledWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  background: theme.palette.common.white,
}));

export default function GraderCard() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.appSettings.user);
  const graderResults = useSelector((state) => state.grader.graderResults);
  const graderStatus = useSelector((state) => state.grader.graderResults.status);
  const {
    isSubmittingGrader, isLoadingScore,
  } = useSelector((state) => state.grader);

  useEffect(() => {
    if (user) {
      dispatch(
        loadInitialGraders({ email: user.email }),
      );
    }
  }, [user]);

  if (isSubmittingGrader || isLoadingScore) {
    return (
      <StyledWrapper>
        <LoadingSkeletonGrader />
      </StyledWrapper>
    );
  }

  return (
    <StyledWrapper>
      { graderStatus !== COMPLETE_GRADER ? (
        <GraderGetYourScore />) : (<GraderResults results={graderResults} />
      )}
    </StyledWrapper>
  );
}

// Engagement Widget Keys
export const ENGAGEMENT_WIDGETS_KEY = 'engagementWidget';
export const WIDGET_FEATURES_KEY = 'widgetFeatures';
export const COLOR_FORM = 'colorForm';
export const WIDGET_FEATURES_FORM = 'widgetFeatures';

// Links
export const SETTINGS_TOOL_SCHEDULE_LINK = '/settings/tools/scheduling';
export const SETTINGS_TOOL_CHATBOT_LINK = '/settings/tools/chatbot';
export const SETTINGS_TOOL_CLICKTOCALL_LINK = '/settings/tools/click-to-call';
export const LEARN_MORE_LINK = "https://localiq.com/products/lead-capture-toolkit"

export const CHAT_VALUE = 'chat';
export const SCHEDULER_VALUE = 'scheduler';
export const CALLTRACKER_VALUE = 'calltracker';
export const MESSAGE_VALUE = 'message';
export const SMS_VALUE = 'sms';

// SMS Notifications Keys
export const SMS = "SMS";
export const NEW_APPT_SMS = 'newAppointmentConfirmationSMS';
export const APPT_REMINDER_SMS = 'appointmentReminderSMS';
export const APPT_RESCHEDULE_CANCEL_SMS = 'rescheduleOrCancelSMS';
export const SMS_FORM = 'smsForm';

// SMS Notifications Keys
export const SMS_NOTIFICATION_DASH = 'newSmsLeadNotifications';
export const SMS_DASH_FORM = 'smsDashForm';

// Notification Settings Keys
export const NOTIFICATION_EMAILS_KEY = 'notificationEmails';
export const SMS_PHONE_NUMBER_KEY = 'phoneNumberSMS';
export const NOTIFICATIONS_FORM = 'notificationsForm';

export const SCHEDULER_WIDGET = "SchedulerPro";
export const CHATBOT_WIDGET = "DIYChatbotPro";
export const CALL_TRACKER_WIDGET = "CallTracking";
export const MESSAGE_WIDGET = "MessagePro";
export const SMS_WIDGET = "SMSPro";

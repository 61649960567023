import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import WelcomeCard from './cards/WelcomeCard';
import RecentActivityCard from './cards/RecentActivityCard';
import CriticalActionCard from './cards/CriticalActionCard';
import WidgetStatusCard from './cards/WidgetStatusCard';
import GraderCard from './cards/GraderCard';
import PerformanceCard from './cards/PerformanceCard';
import FreeKeywordResultCard from './cards/FreeKeywordResultCard';
import UpcomingAppointmentsCard from './cards/UpcomingAppointmentsCard';
import GetTheMostCards from './cards/GetTheMostCards';
import { DISMISS_WELCOME_CARD, INSTALL_WIDGET_TEMPLATE, SETUP_WIDGET_TEMPLATE, KEYWORD_UUID_PARAM_KEY } from './dashboard-constants';
import { hasAnyNonActivatedWidgets } from '../../shared/app-utils';
import { COMPLETED_STATUS } from '../configuration/wizard/wizard-constants';
import { setNewCriticalActionTemplate } from './data/dashboard-slice';
import { DISMISS_BANNER_SECTION } from '../../shared/app-constants';

const { REACT_APP_KEYWORD_TOOL_DOWNLOAD_LINK } = process.env;

const GridContainer = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.text.primary}`,
  borderBottom: 0,
  borderRight: 0,

  '& .MuiGrid-item': {
    border: `1px solid ${theme.palette.text.primary}`,
    borderLeft: 0,
    borderTop: 0,
  },

  margin: '0 auto',
  maxWidth: 1560,

  '@media (min-width: 1560px) and (max-width: 1640px)': {
    // 40px gutters forced on each side
    maxWidth: 'calc(100% - 80px)',
  },
}));

export default function DashboardGrid() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const userTemplate = useSelector((state) => state.appSettings?.user?.regTemplate);
  const wizardStatus = useSelector(state => state.configuration.status);
  const wizardFeatures = {
    activeChatbot: !!useSelector((state) => state.chatbot.firstActivationDate),
    activeScheduler: !!useSelector((state) => state.bookings.firstActivationDate),
    activateCalltracker: !!useSelector((state) => state.callSettings.firstActivationDate),
    activateMessage: !!useSelector((state) => state.contactForm.firstActivationDate),
  };
  const hasTrackingCodeBeenActivated = !!useSelector((state) => state.appSettings.trackingCode.firstCaptureCodeActivation);
  const isLoadingSettingsData = useSelector((state) => state.appSettings.isLoadingSettingsData);
  const criticalActionTemplate = useSelector((state) => state.dashboard.criticalActionTemplate);
  const isTrackingCodeCurrentlyInstalled = useSelector((state) => state.appSettings.trackingCode.isInstalled);
  const hasTrackingCodeCheckCompleted = useSelector((state) => state.appSettings.trackingCode.hasCheckedCodeStatus);
  const trackingCodeIsLoading = useSelector((state) => state.appSettings.trackingCode.isLoading);
  const dismissedWelcome = useSelector((state) => state.businessAttributes[DISMISS_BANNER_SECTION][DISMISS_WELCOME_CARD]);

  const linkToDownload = sessionStorage.getItem(KEYWORD_UUID_PARAM_KEY);
  const keywordUUIDSearchParam = searchParams.get(KEYWORD_UUID_PARAM_KEY);

  const hasNotActivatedWidget = hasAnyNonActivatedWidgets(wizardFeatures);
  const isWizardCompleted = wizardStatus === COMPLETED_STATUS;
  const trackingCodeCheck = hasTrackingCodeCheckCompleted && (!hasTrackingCodeBeenActivated && !isTrackingCodeCurrentlyInstalled);
  const showSetup = hasNotActivatedWidget && !isWizardCompleted;
  const hasCriticalActionTemplate = !!criticalActionTemplate
    || trackingCodeIsLoading || isLoadingSettingsData;
  const displayCriticalActionCard = !isTrackingCodeCurrentlyInstalled || showSetup;
  const displayGetMostSection = !showSetup && !trackingCodeCheck;

  useEffect(() => {
    if (showSetup) {
      dispatch(
        setNewCriticalActionTemplate({ template: SETUP_WIDGET_TEMPLATE }),
      );
    } else if (!showSetup && displayCriticalActionCard) {
      dispatch(
        setNewCriticalActionTemplate({ template: INSTALL_WIDGET_TEMPLATE }),
      );
    } else {
      // This should reset the template
      dispatch(
        setNewCriticalActionTemplate({ template: null }),
      );
    }
  }, [showSetup, displayCriticalActionCard]);

  useEffect(() => {
    if (keywordUUIDSearchParam) {
      const link = `${REACT_APP_KEYWORD_TOOL_DOWNLOAD_LINK}-${keywordUUIDSearchParam}.csv`;
      sessionStorage.setItem(KEYWORD_UUID_PARAM_KEY, link);
    }
  }, [keywordUUIDSearchParam]);

  return (
    <GridContainer container>
      {!dismissedWelcome && (
        <Grid item xs={12}>
          <WelcomeCard userTemplate={userTemplate} />
        </Grid>
      )}
      {linkToDownload && (
        <FreeKeywordResultCard
          hasCriticalActionTemplate={hasCriticalActionTemplate}
          linkToDownload={linkToDownload}
        />
      )}
      {
        displayCriticalActionCard && (
          <CriticalActionCard
            trackingCodeIsLoading={trackingCodeIsLoading || isLoadingSettingsData}
            hasFKTLink={!!linkToDownload}
            userTemplate={criticalActionTemplate}
            isWizardCompleted={isWizardCompleted}
          />
        )
      }
      <Grid item md={3} xs={12}>
        <PerformanceCard wizardFeatures={wizardFeatures} trackingCodeCheck={trackingCodeCheck} />
      </Grid>
      <Grid item md={9} xs={12}>
        <UpcomingAppointmentsCard />
      </Grid>
      <Grid item xs={12}>
        <RecentActivityCard />
      </Grid>
      {
        // Display section only if all critical actions have been completed
        displayGetMostSection && (
          <Grid item xs={12}>
            <GetTheMostCards />
          </Grid>
        )
      }
    </GridContainer>
  );
}

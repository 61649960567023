import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const BodyContentWrapper = styled('div')(({ theme }) => ({
  flex: 1,
  paddingLeft: theme.spacing(6),
  height: '90vh',
  overflow: 'auto',
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
    paddingTop: theme.spacing(2),
  },
}));

/**
 * Layout for a left side and a right side layout.
 * Right side takes all leftover space
 */
export default function SplitColumnLayout({ leftSideContent, rightSideContent, rightContentRef }) {
  return (
    <>
      <div>
        {leftSideContent}
      </div>
      <BodyContentWrapper ref={rightContentRef}>
        {rightSideContent}
      </BodyContentWrapper>
    </>
  );
}

SplitColumnLayout.propTypes = {
  leftSideContent: PropTypes.node.isRequired,
  rightSideContent: PropTypes.node.isRequired,
  rightContentRef: PropTypes.object,
};

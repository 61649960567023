import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button, Typography } from '@mui/material';
import ColorWidgetCard from '../components/cards/ColorWidgetCard';
import SectionCard from '../../../../shared/SectionCard';
import { SectionLayoutWrapper, UnderlineAnchor } from '../../../../shared/settings-shared-components';
import StatusIndicator, { SUCCESS_BACKGROUND, WARNING_BACKGROUND } from '../../../../shared/statusIndicator/StatusIndicator';
import WidgetInstalledIcon from '../../../../images/widget-installed-icon.svg';
import WidgetNotInstalledIcon from '../../../../images/widget-not-installed-icon.svg';
import WidgetFeatures from './WidgetFeatures';
import { ERROR_TYPE } from '../../../../shared/app-constants';
import BannerCard from '../../bookings/cards/BannerCard';
import WidgetPreview from '../../../configuration/wizard/components/WidgetPreview';
import WithDisabled from '../../../tools/components/WithDisabled';
import { trackLearnMoreClick } from '../../../../shared/analytics-utils';
import { WIDGET_COLOR_KEY } from '../../../configuration/wizard/wizard-constants';
import { CALLTRACKER_VALUE, CHAT_VALUE, LEARN_MORE_LINK, MESSAGE_VALUE, SCHEDULER_VALUE, SMS_VALUE, WIDGET_FEATURES_KEY } from '../settings-constants';

const WidgetTitle = styled(Typography)(() => ({
  fontSize: '24px',
  fontFamily: 'Unify Sans Semibold',
  marginBottom: '16px',
}));

const SectionWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '24px',
  marginTop: '24px',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}));

const CustomDivider = styled('hr')(() => ({
  width: '100%',
  background: '#716F6F',
  height: '1.5px',
  border: '0px',
}));

const LogOutWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

const WidgetWrapper = styled('div')(({ theme, $isLarge }) => ({
  marginTop: theme.spacing(2),
  border: '2px dashed #716F6F',
  borderRadius: 8,
  padding: theme.spacing(3),
  width: $isLarge ? 464 : 400,

  [theme.breakpoints.down('md')]: {
    border: 'none',
    padding: 0,
    width: 342,
    paddingBottom: theme.spacing(3),

    '& div': {
      padding: 0,
    },

  },

  '& .previewTitle': {
    fontSize: 20,
    lineHeight: '27px',
    paddingBottom: theme.spacing(2),
    fontFamily: 'Unify Sans SemiBold',
  },

  '& div div.widgetFrame': {
    height: $isLarge ? 615 : 530,
    width: $isLarge ? 464 : 400,

    '&.mobileWidgetFrame': {
      width: 342,
      height: 525,
    },
  },
}));

const {
  REACT_APP_ACCOUNT_SERVICES_UI_ROOT,
} = process.env;

const ID_ROOT = 'engagement-widget';

export default function EngagementWidget({ isDisabled }) {
  const isInstalled = useSelector((state) => state.appSettings.trackingCode.isInstalled);
  const hasError = useSelector((state) => state.settings.hasError);
  const isSchedulerOn = useSelector(
    (state) => state.settings.engagementWidget[WIDGET_FEATURES_KEY][SCHEDULER_VALUE],
  );
  const isChatOn = useSelector(
    (state) => state.settings.engagementWidget[WIDGET_FEATURES_KEY][CHAT_VALUE],
  );
  const isCallTrackerOn = useSelector(
    (state) => state.settings.engagementWidget[WIDGET_FEATURES_KEY][CALLTRACKER_VALUE],
  );
  const isMessageOn = useSelector(
    (state) => state.settings.engagementWidget[WIDGET_FEATURES_KEY][MESSAGE_VALUE],
  );
  const isSMSOn = useSelector(
    (state) => state.settings.engagementWidget[WIDGET_FEATURES_KEY][SMS_VALUE],
  );
  const existingTheme = useSelector((state) => state.settings.engagementWidget[WIDGET_COLOR_KEY]);
  const initialColor = useSelector((state) => state.settings.engagementWidget.initialColor);
  const isDirty = useSelector((state) => state.settings.isDirty);
  const hasSupportRole = useSelector((state) => state.appSettings.user?.hasSupportRole);
  const shouldDisable = hasSupportRole ? false : isDisabled;

  const handleLearnMoreClick = () => {
    trackLearnMoreClick('Settings');
  };

  const clickedLogOut = () => {
    window.location.replace(`${REACT_APP_ACCOUNT_SERVICES_UI_ROOT}logout`);
  };

  return (
    <>
      {hasSupportRole && (
        <LogOutWrapper>
          <Button id="support-log-out-btn" variant="contained" onClick={clickedLogOut}> Log Out </Button>
        </LogOutWrapper>
      )}
      <WithDisabled isDisabled={shouldDisable}>
        <BannerCard
          id="engagement-widget-error-banner"
          title="Error"
          description="Your changes were not saved. Please try again."
          showBanner={hasError}
          bannerType={ERROR_TYPE}
        />
        <SectionLayoutWrapper>
          <div style={{ width: '70%' }}>
            <WidgetTitle id={`${ID_ROOT}-title`}>Engagement Widget</WidgetTitle>
            <Typography id={`${ID_ROOT}-description`}>
              LocaliQ&#39;s engagement widget makes it quick and easy for current and
              potential customers to connect with you.&nbsp;
              <UnderlineAnchor id={`${ID_ROOT}-learn-more`} href={LEARN_MORE_LINK} target="_blank" onClick={handleLearnMoreClick}>Learn More</UnderlineAnchor>
            </Typography>
            <br />
            <Typography sx={{ fontSize: '16px' }} id={`${ID_ROOT}-description-extended`}> LocaliQ Code is required for the widget to integrate on your website.</Typography>
          </div>
          <StatusIndicator
            id="widget-status-indicator"
            mainText={isInstalled ? 'Installed' : 'Not Installed'}
            secondaryText="LocaliQ Code Status"
            icon={isInstalled ? WidgetInstalledIcon : WidgetNotInstalledIcon}
            background={isInstalled ? SUCCESS_BACKGROUND : WARNING_BACKGROUND}
          />
        </SectionLayoutWrapper>
        <SectionWrapper>
          <SectionCard
            id="widget-color-settings"
            title="Color"
            showDividingLine={false}
            tooltipText="Select a color that goes best with your brand."
          >
            <ColorWidgetCard
              existingTheme={existingTheme}
              originalTheme={initialColor}
              disabled={shouldDisable}
            />
            <CustomDivider />
            <WidgetFeatures disabled={shouldDisable} />
          </SectionCard>
          <WidgetWrapper id={`${ID_ROOT}-preview-section`} $isLarge={hasSupportRole}>
            <Typography className="previewTitle" id={`${ID_ROOT}-preview-section-title`}>Preview</Typography>
            <WidgetPreview
              isSchedulerOn={isSchedulerOn}
              isCallTrackerOn={isCallTrackerOn}
              isChatOn={isChatOn}
              isMessageOn={isMessageOn}
              isSMSOn={hasSupportRole ? isSMSOn : false}
              existingTheme={isDirty ? existingTheme : initialColor}
              clickableSMS={hasSupportRole}
            />
          </WidgetWrapper>
        </SectionWrapper>
      </WithDisabled>
    </>
  );
}

EngagementWidget.propTypes = {
  isDisabled: PropTypes.bool,
};

import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { UnderlineAnchor } from '../../../../shared/settings-shared-components';
import SchedulingIcon from '../../../../images/scheduler-feature.svg';
import ChatbotIcon from '../../../../images/chatbot-feature.svg';
import CallRecorderIcon from '../../../../images/call-feature.svg';
import ContactFormIcon from '../../../../images/contactForm-modal.svg';
import SmsIcon from '../../../../images/sms-feature.svg';
import { WIDGET_FEATURE_KEY } from '../../../configuration/wizard/wizard-constants';
import { ReactComponent as ErrorIcon } from '../../../../images/error-icon-custom.svg';
import {
  CHAT_VALUE, SCHEDULER_VALUE, MESSAGE_VALUE, CALLTRACKER_VALUE, SETTINGS_TOOL_SCHEDULE_LINK,
  SETTINGS_TOOL_CHATBOT_LINK, SETTINGS_TOOL_CLICKTOCALL_LINK, SMS_VALUE, WIDGET_FEATURES_FORM,
  WIDGET_FEATURES_KEY, ENGAGEMENT_WIDGETS_KEY,
} from '../settings-constants';
import { updateFeatures } from '../data/widgetSettings-slice';
import WidgetSettingsStateChangedHook from '../../../../shared/stateChangedHook/WidgetSettingsStateChangedHook';
import WidgetFeatureItem from '../components/WidgetFeatureItem';

export const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: '14px',
}));

const DividingLine = styled('hr')(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const CustomError = styled(ErrorIcon)(({ theme }) => ({
  width: '20px',
  height: '20px',
  marginRight: theme.spacing(1),
  verticalAlign: 'bottom',
}));

const FeatureDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '35px',
}));

const RightTitles = styled('div')(() => ({
  display: 'flex',
  gap: '4rem',
  marginRight: '0.5rem',
}));

const FeatureTitle = styled(Typography)(() => (({ theme }) => ({
  fontSize: 20,
  fontFamily: 'Unify Sans Semibold',
  marginBottom: 16,
  [theme.breakpoints.down('sm')]: {
    marginBottom: 0,
  },
})));

const ColumnTitle = styled(Typography)(() => (({ theme }) => ({
  fontSize: 12,
  fontFamily: 'Unify Sans Semibold',
  color: theme.palette.action.disabledBackground,
})));

const FORM_KEYS = [CHAT_VALUE, SCHEDULER_VALUE, MESSAGE_VALUE, CALLTRACKER_VALUE, SMS_VALUE];

export default function WidgetFeatures() {
  const dispatch = useDispatch();
  const hasSupportRole = useSelector((state) => state.appSettings.user.hasSupportRole);
  const isSchedulingActive = useSelector(
    (state) => state.settings.engagementWidget[WIDGET_FEATURE_KEY][SCHEDULER_VALUE],
  );
  const isChatActive = useSelector(
    (state) => state.settings.engagementWidget[WIDGET_FEATURE_KEY][CHAT_VALUE],
  );
  const isCallSettingsActive = useSelector(
    (state) => state.settings.engagementWidget[WIDGET_FEATURE_KEY][CALLTRACKER_VALUE],
  );
  const isContactFormActive = useSelector(
    (state) => state.settings.engagementWidget[WIDGET_FEATURE_KEY][MESSAGE_VALUE],
  );
  const isSMSActive = useSelector(
    (state) => state.settings.engagementWidget[WIDGET_FEATURE_KEY][SMS_VALUE],
  );
  const hasCallRecorderNumber = useSelector(
    (state) => state.callSettings.generalSettings.originalNumber,
  );

  const schema = yup.object().shape({
    [SCHEDULER_VALUE]: yup.boolean(),
    [CHAT_VALUE]: yup.boolean(),
    [CALLTRACKER_VALUE]: yup.boolean(),
    [MESSAGE_VALUE]: yup.boolean(),
    [SMS_VALUE]: yup.boolean(),
  });

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => ({
      [SCHEDULER_VALUE]: isSchedulingActive,
      [CHAT_VALUE]: isChatActive,
      [CALLTRACKER_VALUE]: hasCallRecorderNumber ? isCallSettingsActive : false,
      [MESSAGE_VALUE]: isContactFormActive,
      [SMS_VALUE]: isSMSActive,
    })),
  });

  const updateFeatureSelection = (isSelected, formName) => {
    dispatch(updateFeatures({ formName, isSelected }));
  };

  return (
    <>
      <FeatureDiv>
        <FeatureTitle id="widget-features-title"> Features</FeatureTitle>
        <RightTitles>
          {!isMobile && <ColumnTitle id="widget-settings-title"> SETTINGS</ColumnTitle>}
          <ColumnTitle id="widget-on-off"> ON/OFF</ColumnTitle>
        </RightTitles>
      </FeatureDiv>
      <FormProvider {...methods}>
        <form>
          <WidgetFeatureItem
            id={SCHEDULER_VALUE}
            title="Scheduling"
            description="Visitors can schedule appointments."
            icon={SchedulingIcon}
            settingsLink={SETTINGS_TOOL_SCHEDULE_LINK}
            switchName={SCHEDULER_VALUE}
            switchValue={SCHEDULER_VALUE}
            switchOnChange={updateFeatureSelection}
            switchIsDisabled={hasSupportRole}
          />
          <DividingLine />
          <WidgetFeatureItem
            id={CHAT_VALUE}
            title="Chatbot"
            description="Engage visitors and answer questions."
            icon={ChatbotIcon}
            settingsLink={SETTINGS_TOOL_CHATBOT_LINK}
            switchName={CHAT_VALUE}
            switchValue={CHAT_VALUE}
            switchOnChange={updateFeatureSelection}
            switchIsDisabled={hasSupportRole}
          />
          <DividingLine />
          <WidgetFeatureItem
            id={CALLTRACKER_VALUE}
            title="Click-to-Call (USA Only)"
            description="Connect with your visitors by phone."
            icon={CallRecorderIcon}
            settingsLink={SETTINGS_TOOL_CLICKTOCALL_LINK}
            switchName={CALLTRACKER_VALUE}
            switchValue={CALLTRACKER_VALUE}
            switchOnChange={updateFeatureSelection}
            switchIsDisabled={!hasCallRecorderNumber || hasSupportRole}
          />
          {!hasCallRecorderNumber && (
            <ErrorMessage id={`${CALLTRACKER_VALUE}-error-message`}>
              <CustomError id={`${CALLTRACKER_VALUE}-error-icon`} />
              Click-to-Call cannot be turned on. Configure&nbsp;
              <UnderlineAnchor id={`${CALLTRACKER_VALUE}-error-icon`} fontSize="14px" target="_self" fontColor="#C20F1E" href={SETTINGS_TOOL_CLICKTOCALL_LINK}>settings</UnderlineAnchor>
              &nbsp;and try again.
            </ErrorMessage>
          )}
          <DividingLine />
          <WidgetFeatureItem
            id={MESSAGE_VALUE}
            title="Contact Form"
            description="Collect leads from incoming messages."
            icon={ContactFormIcon}
            switchName={MESSAGE_VALUE}
            switchValue={MESSAGE_VALUE}
            switchOnChange={updateFeatureSelection}
            switchIsDisabled={hasSupportRole}
          />
          {
            hasSupportRole && (
              <>
                <DividingLine />
                <WidgetFeatureItem
                  id={SMS_VALUE}
                  title="SMS Widget "
                  description="When enabled, the SMS widget will show on the advertisers website."
                  icon={SmsIcon}
                  switchName={SMS_VALUE}
                  switchValue={SMS_VALUE}
                  switchOnChange={updateFeatureSelection}
                />
              </>
            )
          }
        </form>
        <WidgetSettingsStateChangedHook
          formName={WIDGET_FEATURES_FORM}
          formKeys={FORM_KEYS}
          subsectionKey={ENGAGEMENT_WIDGETS_KEY}
          nestedKey={WIDGET_FEATURES_KEY}
        />
      </FormProvider>
    </>
  );
}

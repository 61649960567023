import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { ReactComponent as AdvancedGradeIcon } from '../../assets/grade-advanced.svg';
import { ReactComponent as IntermediateGradeIcon } from '../../assets/grade-intermediate.svg';
import { ReactComponent as FoundationalGradeIcon } from '../../assets/grade-foundational.svg';
import { determineScoreObject } from '../../../graders/website/grader-utils';
import { GRADER_PATH } from '../../../../shared/app-constants';
import { trackDashboardCardClick } from '../../../../shared/analytics-utils';

const StyledContainer = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  height: '100%',
}));

const StyledInnerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  padding: theme.spacing(4, 5),
}));

const StyledTitle = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 600,
}));

const StyledContentContainer = styled('div')(({ theme, isMobile }) => ({
  display: 'flex',
  flexDirection: isMobile ? 'column' : 'row',
  gap: theme.spacing(3),
}));

const SummaryGradesContainer = styled('div')(({ theme, borderColor }) => ({
  border: `3px solid ${borderColor}`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: '8px',
  padding: theme.spacing(3),
  gap: theme.spacing(3),
}));

const StyledOverallScoreContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'max-content',
  alignItems: 'center',
}));

const StyledAchievedScore = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '24px',
}));

const StyledSeparator = styled('span')(() => ({
  border: '1px solid black',
  width: '100%',
}));

const StyledTotalScore = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '24px',
}));

const StyledPresenceContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const StyledPresence = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 600,
  letterSpacing: '1px',
  textTransform: 'uppercase',
}));

const StyledPresenceLevel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
  fontSize: '20px',
  fontWeight: 600,
  letterSpacing: '0.18px',
}));

const GradesDetailInfoContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
}));

const StyledLineBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(1, 0),
  justifyContent: 'space-between',
  borderBottom: `2px solid ${theme.palette.action.disabledBackground}`,
}));

const LinkExploreGrades = styled(Link)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '18px',
  textDecoration: 'underline',
  color: theme.palette.primary.main,
}));

const ScoreIconContainer = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  fontSize: '16px',
  fontWeight: 600,
}));

const StyledRowText = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 600,
}));

const LEVEL_TO_ICON_MAP = {
  Foundational: <FoundationalGradeIcon height={20} />,
  Intermediate: <IntermediateGradeIcon height={20} />,
  Advanced: <AdvancedGradeIcon height={20} />,
};

export default function GraderResults({ results }) {
  const overallScore = results.score;
  const websiteQualityScore = results.subCategories.websiteBuild;
  const socialScore = results.subCategories.social;
  const digitalAdvertisingScore = results.subCategories.advertising;

  const overallScoreObject = determineScoreObject(overallScore);
  const websiteQualityScoreObject = determineScoreObject(websiteQualityScore);
  const socialScoreObject = determineScoreObject(socialScore);
  const digitalAdvertisingScoreObject = determineScoreObject(digitalAdvertisingScore);

  const trackExploreClick = () => {
    trackDashboardCardClick('Grader', 'Explore Your Grades');
  }

  return (
    <StyledContainer>
      <StyledInnerContainer>
        <StyledTitle>Website Grader+</StyledTitle>
        <StyledContentContainer isMobile={isMobile}>
          <SummaryGradesContainer borderColor={overallScoreObject.color}>
            <StyledOverallScoreContainer>
              <StyledAchievedScore>{overallScore}</StyledAchievedScore>
              <StyledSeparator />
              <StyledTotalScore>100</StyledTotalScore>
            </StyledOverallScoreContainer>
            <StyledPresenceContainer>
              <StyledPresence>Online Presence</StyledPresence>
              <StyledPresenceLevel>
                {overallScoreObject.level}
                {LEVEL_TO_ICON_MAP[overallScoreObject.level]}
              </StyledPresenceLevel>
            </StyledPresenceContainer>
          </SummaryGradesContainer>

          <GradesDetailInfoContainer>
            <StyledLineBox>
              <StyledRowText>Website Quality</StyledRowText>
              <ScoreIconContainer>
                {websiteQualityScore}
                /100
                {LEVEL_TO_ICON_MAP[websiteQualityScoreObject.level]}
              </ScoreIconContainer>
            </StyledLineBox>
            <StyledLineBox>
              <StyledRowText>Social & Local Presence</StyledRowText>
              <ScoreIconContainer>
                {socialScore}
                /100
                {LEVEL_TO_ICON_MAP[socialScoreObject.level]}
              </ScoreIconContainer>
            </StyledLineBox>
            <StyledLineBox style={{ border: 'none' }}>
              <StyledRowText>Digital Advertising</StyledRowText>
              <ScoreIconContainer>
                {digitalAdvertisingScore}
                /100
                {LEVEL_TO_ICON_MAP[digitalAdvertisingScoreObject.level]}
              </ScoreIconContainer>
            </StyledLineBox>
          </GradesDetailInfoContainer>

        </StyledContentContainer>
        <LinkExploreGrades onClick={trackExploreClick} to={GRADER_PATH}>Explore Your Grades</LinkExploreGrades>
      </StyledInnerContainer>
    </StyledContainer>
  );
}

GraderResults.propTypes = {
  results: PropTypes.object,
};

import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';
import { Typography, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as GraderIcon } from '../../assets/grader-icon.svg';
import { GRADER_PATH } from '../../../../shared/app-constants';
import GraderBackground from '../../assets/grader-background.svg';
import { trackDashboardCardClick } from '../../../../shared/analytics-utils';

const StyledContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  backgroundImage: `url(${GraderBackground})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: '100%',
  padding: theme.spacing(5, 10),
  height: '100%',

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(5, 3),
    backgroundPosition: '40%',
  },
}));

const StyledInnerContainer = styled('div')(({ theme, isMobile }) => ({
  display: 'flex',
  flexDirection: isMobile ? 'column' : 'row',
  gap: theme.spacing(4),
  alignItems: 'center',
}));

const StyledIconSection = styled('div')();

const StyledContentSection = styled('div')(({ isMobile }) => ({
  textAlign: isMobile && 'center',
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 600,
  marginBottom: theme.spacing(1),
}));

const StyledSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 400,
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  background: theme.palette.common.white,
  fontSize: '14px',
  borderRadius: '30px',
  padding: theme.spacing(1, 3),
  color: `${theme.palette.primary.main}`,
  cursor: 'pointer',
  '&:hover': {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

export default function GraderGetYourScore() {
  const navigate = useNavigate();

  const goToGrader = () => {
    trackDashboardCardClick('Grader', 'Get Your Score');
    navigate(GRADER_PATH);
  };

  return (
    <StyledContainer>
      <StyledInnerContainer isMobile={isMobile}>
        <StyledIconSection><GraderIcon /></StyledIconSection>
        <StyledContentSection isMobile={isMobile}>
          <StyledTitle>Does your website make the grade?</StyledTitle>
          <StyledSubTitle>
            Get a free website score, including competitor scores
            and category details, in under 90 seconds.
          </StyledSubTitle>
          <StyledButton onClick={goToGrader}>Get Your Score</StyledButton>
        </StyledContentSection>
      </StyledInnerContainer>
    </StyledContainer>
  );
}
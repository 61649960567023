import styled from '@emotion/styled';
import GraderTotalStatusBox from './GraderTotalStatusBox';
import WebsiteSectionGraderStatus from './WebsiteSectionGraderStatus';
import SkeletonText from '../SkeletonText';

const StyledWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 4),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 3),
  },
}));
const StyledHeadWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  maxWidth: '246px',

  [theme.breakpoints.down('md')]: {
    maxWidth: 'unset',
  },
}));

const StyledMainWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  margin: theme.spacing(3, 0, 2, 0),
  gap: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const StyledStatusRowsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(1),
}));

export default function LoadingSkeletonGrader() {
  return (
    <StyledWrapper>
      <StyledHeadWrapper>
        <SkeletonText animation="wave" variant="string" width="100%" height={30} />
      </StyledHeadWrapper>
      <StyledMainWrapper>
        <GraderTotalStatusBox />
        <StyledStatusRowsWrapper>
          <WebsiteSectionGraderStatus />
          <WebsiteSectionGraderStatus />
          <WebsiteSectionGraderStatus />
        </StyledStatusRowsWrapper>
      </StyledMainWrapper>
      <SkeletonText animation="wave" variant="string" width={138} height={40} />
    </StyledWrapper>
  );
}

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ValidationProvider, IQCheckbox } from '@gannettdigital/shared-react-components';
import { Grid, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from '@emotion/styled';
import SectionCard from '../../../../../shared/SectionCard';
import { NOTIFICATIONS_SETTINGS_SECTION, SMS_NOTIFICATIONS_SECTION, updateSmsNotifications } from '../../data/notifications-slice';
import { APPT_REMINDER_SMS, APPT_RESCHEDULE_CANCEL_SMS, NEW_APPT_SMS, SMS, SMS_FORM, SMS_NOTIFICATION_DASH, SMS_PHONE_NUMBER_KEY } from '../../settings-constants';
import { GridRow } from '../../../../../shared/settings-shared-components';
import { isValidUSPhoneNumberLength } from '../../../../../shared/validation-utils';
import WidgetSettingsStateChangedHook from '../../../../../shared/stateChangedHook/WidgetSettingsStateChangedHook';

const CommunicationTitle = styled(Typography)(() => ({
  fontFamily: 'Unify Sans Semibold',
  fontSize: 18,
  lineHeight: '27px'
}));

const DescriptionWrapper = styled('div')(({ theme }) => ({
  width: '70%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginBottom: '12px'
  }
}));

const CheckboxWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center'
}));

const GridWrapper = styled(GridRow)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
    flexDirection: 'column'
  }
}));

const FORM_KEYS = [
  NEW_APPT_SMS,
  APPT_REMINDER_SMS,
  APPT_RESCHEDULE_CANCEL_SMS,
];

export default function SMSNotificationsCard() {
  const dispatch = useDispatch();
  const newApptNotification = useSelector((state) => state.settings.notifications[SMS_NOTIFICATIONS_SECTION][NEW_APPT_SMS]);
  const apptReminderNotification = useSelector((state) => state.settings.notifications[SMS_NOTIFICATIONS_SECTION][APPT_REMINDER_SMS]);
  const rescheduleNotification = useSelector((state) => state.settings.notifications[SMS_NOTIFICATIONS_SECTION][APPT_RESCHEDULE_CANCEL_SMS]);
  const smsPhones = useSelector((state) => state.settings.notifications[NOTIFICATIONS_SETTINGS_SECTION][SMS_PHONE_NUMBER_KEY]);
  const smsPhone = smsPhones[0]?.phone || '';
  const schema = yup.object().shape({
    [NEW_APPT_SMS]: yup.boolean(),
    [APPT_REMINDER_SMS]: yup.boolean(),
    [APPT_RESCHEDULE_CANCEL_SMS]: yup.boolean(),
  }).test(({
    message: 'Phone number missing',
    test: (val) => {
      if (val[NEW_APPT_SMS] || val[APPT_REMINDER_SMS] || val[APPT_RESCHEDULE_CANCEL_SMS]) {
        return smsPhone && isValidUSPhoneNumberLength(smsPhone);
      }
      return true;
    },
  }));

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      [NEW_APPT_SMS]: newApptNotification,
      [APPT_REMINDER_SMS]: apptReminderNotification,
      [APPT_RESCHEDULE_CANCEL_SMS]: rescheduleNotification
    }
  });

  const { trigger } = methods;

  const handleInputOnChange = (event) => {
    const { value, name } = event.target;
    const isSelected = value !== 'true';

    dispatch(
      updateSmsNotifications({ isSelected, value, fieldName: name })
    )
  };

  useEffect(() => {
    trigger();
  }, [smsPhone]);

  return (
    <SectionCard
      id='sms-notifications'
      title='SMS Notifications for Scheduling'
      description="Select if you'd like to receive SMS notifications for appointments. Customers will always have an SMS option available during booking."
      showDividingLine={false}>
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <form style={{ marginTop: '24px' }}>
              <Grid container spacing={2}>
                <GridWrapper item>
                  <DescriptionWrapper>
                    <CommunicationTitle>New Appointment Confirmation</CommunicationTitle>
                    <Typography>Notification immediately sent after successfully booking an appointment.</Typography>
                  </DescriptionWrapper>
                  <CheckboxWrapper>
                    <IQCheckbox
                      label={SMS}
                      field={NEW_APPT_SMS}
                      onChange={handleInputOnChange}
                    />
                  </CheckboxWrapper>
                </GridWrapper>

                <GridWrapper item>
                  <DescriptionWrapper>
                    <CommunicationTitle>Appointment Reminder</CommunicationTitle>
                    <Typography>Reminder 2 hours before the appointment time.</Typography>
                  </DescriptionWrapper>
                  <CheckboxWrapper>
                    <IQCheckbox
                      label={SMS}
                      field={APPT_REMINDER_SMS}
                      onChange={handleInputOnChange}
                    />
                  </CheckboxWrapper>
                </GridWrapper>

                <GridWrapper item>
                  <DescriptionWrapper>
                    <CommunicationTitle>Reschedule or Cancellation Notification </CommunicationTitle>
                    <Typography>Notification for any rescheduled or cancelled appointments.</Typography>
                  </DescriptionWrapper>
                  <CheckboxWrapper>
                    <IQCheckbox
                      label={SMS}
                      field={APPT_RESCHEDULE_CANCEL_SMS}
                      onChange={handleInputOnChange}
                    />
                  </CheckboxWrapper>
                </GridWrapper>
              </Grid>
              <WidgetSettingsStateChangedHook
                formName={SMS_FORM}
                formKeys={FORM_KEYS}
                subsectionKey={SMS_NOTIFICATIONS_SECTION}
              />
            </form>
          </FormProvider>
        </ValidationProvider>
    </SectionCard>
  )
}
import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import SkeletonText from '../SkeletonText';

const StyledTotalStatusWrapper = styled('div')(({ theme }) => ({
  border: '2px solid #0000000A',
  borderRadius: '8px',
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(3),
}));

const StyledTotalStatusInfoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing(1),
}));

const StyledTotalStatusStringWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '0px',
  margin: '0px',
  gap: theme.spacing(1),
}));

const SkeletonPercentage = styled(Skeleton)(({ bgcolor = '#0000000A' }) => ({
  '&.MuiSkeleton-string': {
    borderRadius: '4px',
    background: bgcolor,
  },
}));

export default function GraderTotalStatusBox() {
  return (
    <StyledTotalStatusWrapper>
      <SkeletonPercentage animation="wave" variant="string" width={43} height={58} />
      <StyledTotalStatusInfoWrapper>
        <SkeletonText animation="wave" variant="string" width={123} height={15} />
        <StyledTotalStatusStringWrapper>
          <SkeletonText animation="wave" variant="string" width={130} height={25} />
          <SkeletonText animation="wave" variant="string" width={24} height={24} />
        </StyledTotalStatusStringWrapper>
      </StyledTotalStatusInfoWrapper>
    </StyledTotalStatusWrapper>
  );
}
